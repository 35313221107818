<!--
    Render a card containing scan metadata.
-->

<template>
    <div class="card flush">
        <dl class="scan-metadata">
            <div v-for="meta in metadata">
                <dt>{{ meta.label }}</dt>
                <dd>
                    <key-value-table v-if="'object' === typeof meta.value"
                        :items="meta.value"
                    />
                    <template v-else>
                        {{ meta.value }}
                    </template>
                </dd>
            </div>
        </dl>
    </div>
</template>

<script>
    import KeyValueTable from './KeyValueTable';

    export default {
        components: {
            KeyValueTable,
        },

        props: {
            metadata: {
                required: true,
                type: Object,
            },
        },
    };
</script>
