<!--
    Display details for a single Lighthouse audit.
-->

<template>
    <div :id="audit.id"
        :class="type"
        class="audit card"
    >
        <header class="audit-name">
            <h3>
                {{ audit.name }}
            </h3>
            <span v-if="'application' === audit.layer"
                class="layer"
                title="This issue is related to your front-end performance"
            >
                <!-- Heroicons: document-text -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                </svg>
            </span>
            <span v-if="'server' === audit.layer"
                class="layer"
                title="This issue is likely related to server performance"
            >
                <!-- Heroicons: server -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z" clip-rule="evenodd" />
                </svg>
            </span>
        </header>
        <div class="audit-details">
            <dl class="audit-stats">
                <div v-for="value, label in stats">
                    <dt>{{ label }}</dt>
                    <dd>{{ value }}</dd>
                </div>
                <div v-if="audit.priority && 'metric' === type">
                    <dt>Impact</dt>
                    <dd :class="priorityClass"
                        class="audit-priority-level"
                    >
                        {{ getPriority(audit.priority) }}
                    </dd>
                </div>
            </dl>

            <div class="audit-recommendations">
                <div v-if="audit.description"
                    class="audit-description"
                    v-html="markdown(audit.description)"
                />

                <audit-details :data="audit.details" />

                <h4 v-if="audit.recommendations.length" class="recommendations-heading">You might consider:</h4>
                <ul v-if="audit.recommendations.length">
                    <li
                        v-for="recommendation in audit.recommendations"
                        v-html="markdown(recommendation)"
                    />
                </ul>
                <p v-if="audit.learnMore"
                    class="learn-more"
                >
                    <a :href="audit.learnMore.href"
                        v-html="markdown(audit.learnMore.anchor)"
                    />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import AuditDetails from './AuditDetails';

    const md = require('markdown-it')();

    export default {
        components: {
            AuditDetails,
        },

        props: {
            audit: {
                required: true,
                type: Object,
            },
            type: {
                required: true,
                type: String,
                validator: prop => ['opportunity', 'metric'].includes(prop),
            }
        },

        data() {
            const stats = {};

            if ('metric' === this.type) {
                stats['Target'] = this.audit.target;
                stats['Actual'] = this.audit.value.display;
            } else {
                stats['Estimated Savings'] = Math.round(this.audit.details.overallSavingsMs) + 'ms';
            }

            return {
                stats,
            };
        },

        computed: {
            priorityClass() {
                return `audit-priority-level-${this.audit.priority}`;
            },
        },

        methods: {
            /**
             * Translate a priority number to a string ("High", "Medium", or "Low").
             *
             * @return string
             */
            getPriority() {
                if (1 === this.audit.priority) {
                    return 'High';
                } else if (2 === this.audit.priority) {
                    return 'Medium';
                }

                return 'Low';
            },

            /**
             * Render a Markdown string.
             *
             * @param {string} str - The Markdown string to parse.
             *
             * @return string
             */
            markdown(str) {
                return md.render(str);
            },
        },
    };
</script>
