<!--
    Renders details related to a specific audit.

    Generally, this will be a pass-through to the DataTable component.
-->

<template>
    <data-table v-if="isTable"
        :headings="data.headings"
        :items="data.items"
    />
</template>

<script>
    import DataTable from '../Tables/DataTable';

    export default {
        components: {
            DataTable,
        },

        props: {
            data: {
                required: true,
                type: Array|Object,
            },
        },

        computed: {
            isTable() {
                return this.data.type === 'opportunity'
                    || this.data.type === 'table';
            },
        },
    };
</script>
