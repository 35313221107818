<!--
    Dynamically round milliseconds to more human-readable forms.

    EXAMPLES

    # 150ms
    <milliseconds ms="150" />

    # 15s
    <milliseconds ms="15000" />
-->

<template>
    <span v-if="useSeconds"
        :title="title"
    >
        {{ seconds }}s
    </span>
    <span v-else>
        {{ Math.round(ms) }}ms
    </span>
</template>

<script>
    export default {
        props: {
            ms: {
                required: true,
                type: Number,
            },
        },

        data() {
            return {
                seconds: (this.ms / 1000).toFixed(1),
                title: Math.round(this.ms) + ' milliseconds',
                useSeconds: 1000 < this.ms,
            };
        },
    };
</script>
