<!--
    Render a generic data table.
-->

<template>
    <div class="table-wrap">
        <table class="data-table">
            <thead v-if="headings">
                <tr>
                    <th v-for="heading in headings"
                        :class="[heading.itemType, heading.valueType]"
                        scope="col"
                    >
                        {{ heading.label || heading.text }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items"
                    :key="item.key"
                >
                    <td v-for="heading in headings"
                        :class="[heading.itemType, heading.valueType]"
                    >
                        <!-- Render an image and, if available, the selector path. -->
                        <figure v-if="'object' === typeof item[heading.key]">
                            <img v-if="'image' === item[heading.key].type"
                                :src="item[heading.key].attributes.src"
                                :alt="item[heading.key].attributes.alt"
                                class="mb-2"
                            />
                            <img v-else-if="'video' === item[heading.key].type && item[heading.key].attributes.poster"
                                :src="item[heading.key].attributes.poster"
                                alt=""
                                class="mb-2"
                            />
                            <figcaption v-if="item[heading.key].selectorPath"
                                class="selector-path"
                            >
                                {{ item[heading.key].selectorPath }}
                            </figcaption>
                            <figcaption v-if="item[heading.key].url">
                                <source-location :url="item[heading.key].url"
                                    :line="item[heading.key].line"
                                    :column="item[heading.key].column"
                                />
                            </figcaption>
                        </figure>

                        <!-- The table cell is an image thumbnail. -->
                        <a v-else-if="columnType(heading, 'thumbnail')"
                            :href="item[heading.key]"
                            target="_blank"
                        >
                            <img :src="item[heading.key]"
                                alt=""
                                class="thumbnail"
                            />
                        </a>

                        <!-- The table cell contains a URL. -->
                        <a v-else-if="columnType(heading, 'url')"
                            :href="item[heading.key]"
                            target="_blank"
                        >
                            {{ item[heading.key] }}
                        </a>

                        <!-- Timings, sizes. -->
                        <template v-else-if="columnType(heading, 'ms') || columnType(heading, 'timespanMs')">
                            <milliseconds :ms="item[heading.key]" />
                        </template>

                        <template v-else-if="columnType(heading, 'bytes')">
                            <bytes :bytes="item[heading.key]" />
                        </template>

                        <!-- Finally, if nothing else matches, just render the contents. -->
                        <template v-else>
                            {{ item[heading.key] }}
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Bytes from '../Helpers/Bytes';
    import Milliseconds from '../Helpers/Milliseconds';
    import SourceLocation from '../Helpers/SourceLocation';

    export default {
        components: {
            Bytes,
            Milliseconds,
            SourceLocation,
        },

        props: {
            headings: {
                required: true,
                type: Array,
            },
            items: {
                required: true,
                type: Array,
            },
        },

        methods: {
            /**
             * Determine if the column is of a certain type, based on its heading.
             *
             * @param {object} heading - The heading object.
             * @param {string} type - The type to check.
             *
             * @return {bool}
             */
            columnType(heading, type) {
                return type === heading.itemType || type === heading.valueType;
            },
        },
    };
</script>
