<!--
    The main entry point for showing scan results.

    Currently, this is a pass-through to Lighthouse/Report, but as we add support for multiple
    reports on a single scan we'll move more of the logic up to this component.
-->

<template>
    <lighthouse-report :id="id"
        :apiEndpoint="apiEndpoint"
        :displayUrl="displayUrl"
        :report="report"
    />
</template>

<script>
    import LighthouseReport from './Lighthouse/Report';

    /**
     * The ScanResults component can retrieve results in three ways:
     *
     * 1. They may be passed directly via the "report" property.
     * 2. They may be pulled from the API endpoint defined via the "apiEndpoint" property.
     * 3. If a broadcast is received on "scans.{id}", they will be retrieved via method #2.
     */
    export default {
        components: {
            LighthouseReport,
        },

        /**
         * @var {string} apiEndpoint The API endpoint used to retrieve scan results.
         * @var {string} id          The UUID of the Scan model.
         * @var {object} report      Report details that have already been retrieved. Passing this
         *                           property will remove the need to call the API endpoint.
         */
        props: {
            apiEndpoint: {
                required: true,
                type: String,
            },
            displayUrl: {
                required: true,
                type: String,
            },
            id: {
                required: true,
                type: String,
            },
            report: {
                required: false,
                type: Object,
            },
        },
    };
</script>
