<!--
    Format a string corresponding to a source file, optionally appending the line/column.

    EXAMPLES

    # <a href="https://example.com" target="_blank">https://example.com</a>
    <source-location url="https://example.com">

    # <a href="https://example.com" target="_blank">https://example.com</a> <span class="location">(5,0)</span>
    <source-location url="https://example.com" line="5" column="0">
-->

<template>
    <span class="source-location">
        <a :href="url"
            target="_blank"
        >
            {{ url }}
        </a>

        <span v-if="location"
            class="location"
        >
            {{ location }}
        </span>
    </span>
</template>

<script>
    export default {
        props: {
            column: {
                required: false,
                type: Number,
                default: -1,
            },
            line: {
                required: false,
                type: Number,
                default: 0,
            },
            url: {
                required: true,
                type: String,
            },
        },

        data() {
            const location = [];

            if (this.line > 1) {
                location.push(`line ${this.line}`);
            }

            if (this.column >= 0) {
                location.push(`column ${this.column}`);
            }

            return {
                location: location ? '(' + location.join(',') + ')' : '',
            };
        }
    };
</script>
