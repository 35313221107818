<!--
    Render a <time> element with appropriate formatting.

    This component allows us to render a timestamp according to the user's local timezone, while
    still providing a machine-readable form of the actual, UTC-based timestamp.
-->

<template>
    <time :datetime="datetime"
        :title="title"
    >
        {{ localTime }}
    </time>
</template>

<script>
    export default {
        props: {
            datetime: {
                required: true,
                type: String,
            },
            title: {
                required: false,
                type: String,
                default: '',
            },
        },

        data() {
            const date = new Date(this.datetime);

            return {
                localTime: date.toLocaleString([], {
                    dateStyle: 'long',
                    timeStyle: 'short',
                }),
            };
        },
    };
</script>
