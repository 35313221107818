require('./bootstrap');

import ScanResults from './components/ScanResults';

window.Vue = require('vue').default;

const app = new Vue({
    el: '#content',
    components: {
        ScanResults,
    },
});
