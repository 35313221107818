<!--
    Render a single Lighthouse report.
-->

<template>
    <div
        class="scan-results"
        :class="{ loading: isLoading }"
    >
        <p v-if="isLoading"
            class="loading-text"
        >
            Testing your site performance, this may take a moment&hellip;
        </p>

        <div v-if="scanFailed" class="max-wd-md mx-auto py-6 text-center">
            <p class="text-2xl font-medium mb-2">We were unable to complete a scan of {{ displayUrl }}.</p>
            <p class="text-xl mb-2">Does the page exist and is it publicly accessible?</p>
            <p class="text-xl">Please check the page and <a href="/">try again</a>.</p>
        </div>

        <template v-if="results">
            <section v-if="results.grade && results.summary"
                class="card report-summary"
            >
                <letter-grade :score="results.grade.score"
                    class="pt-2"
                />

                <div>
                    <p class="text-xl font-semibold">
                        {{ results.summary.title }}
                    </p>
                    <div v-if="results.summary.body"
                        v-html="markdown(results.summary.body)"
                    />

                    <div v-if="appIssues.length && serverIssues.length"
                        class="mt-6 flex flex-row gap-4 items-center"
                    >
                        <span class="block text-sm text-gray-500 font-medium">Show only:</span>
                        <button class="btn btn-secondary"
                            :class="filterState('appIssues')"
                            @click="applyFilter('appIssues')"
                        >
                            <!-- Heroicons: document-text -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>
                            Application issues
                            <span class="issue-count">({{ appIssues.length }})</span>
                        </button>
                        <button class="btn btn-secondary"
                            :class="filterState('serverIssues')"
                            @click="applyFilter('serverIssues')"
                        >
                            <!-- Heroicons: server -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" /></svg>
                            Server issues
                            <span class="issue-count">({{ serverIssues.length }})</span>
                        </button>
                    </div>
                </div>
            </section>

            <section v-if="hasMetrics"
                class="report-section"
            >
                <h2>Key Metrics</h2>
                <p>These are the areas that will directly impact your score.</p>
                <div class="audits">
                    <audit v-for="metric in filteredResults.metrics"
                        :key="metric.id"
                        :audit="metric"
                        type="metric"
                    />
                </div>
            </section>

            <section v-if="hasOpportunities"
                class="report-section"
            >
                <h2>Opportunities</h2>
                <p>Actionable steps you can take to improve your score.</p>
                <div class="audits">
                    <audit v-for="opportunity in filteredResults.opportunities"
                        :key="opportunity.id"
                        :audit="opportunity"
                        type="opportunity"
                    />
                </div>
            </section>

            <section id="meta" class="report-section">
                <h2>Scan Details</h2>
                <p>Details about the scanning environment.</p>
                <scan-meta :metadata="results.meta" />
            </section>
        </template>
    </div>
</template>

<script>
    import Audit from './Audit';
    import LetterGrade from '../Helpers/LetterGrade';
    import LocalizedTime from '../Helpers/LocalizedTime';
    import ScanMeta from '../Tables/ScanMetadata';

    const md = require('markdown-it')();

    /**
     * The Results component can retrieve results in three ways:
     *
     * 1. They may be passed directly via the "report" property.
     * 2. They may be pulled from the API endpoint defined via the "apiEndpoint" property.
     * 3. If a broadcast is received on "scans.{id}", they will be retrieved via method #2.
     */
    export default {
        components: {
            Audit,
            LetterGrade,
            LocalizedTime,
            ScanMeta,
        },

        data() {
            return {
                currentFilter: null,
                filteredResults: this.report,
                isLoading: true,
                results: this.report,
                scanFailed: false,
            }
        },

        /**
         * @var {string} apiEndpoint The API endpoint used to retrieve scan results.
         * @var {string} id          The UUID of the Scan model.
         * @var {object} report      Report details that have already been retrieved. Passing this
         *                           property will remove the need to call the API endpoint.
         */
        props: {
            apiEndpoint: {
                required: true,
                type: String,
            },
            displayUrl: {
                required: true,
                type: String,
            },
            id: {
                required: true,
                type: String,
            },
            notices: {
                required: false,
                type: Array,
            },
            report: {
                required: false,
                type: Object,
            },
        },

        computed: {
            appIssues() {
                return this.filterByLayer('application');
            },
            hasMetrics() {
                return 0 < Object.keys(this.filteredResults.metrics).length;
            },
            hasOpportunities() {
                return 0 < Object.keys(this.filteredResults.opportunities).length;
            },
            serverIssues() {
                return this.filterByLayer('server');
            },
        },

        methods: {
            /**
             * Apply the given filter.
             */
            applyFilter(filter) {
                if (this.isFilterActive(filter)) {
                    this.filteredResults.metrics = this.results.metrics;
                    this.filteredResults.opportunities = this.results.opportunities;
                    this.currentFilter = null;
                } else {
                    this.filteredResults.metrics = this[filter].metrics;
                    this.filteredResults.opportunities = this[filter].opportunities;
                    this.currentFilter = filter;
                }
            },

            /**
             * Fetch scan results from the given API endpoint.
             */
            fetchResults() {
                window.axios.get(this.apiEndpoint)
                    .then(response => {
                        // Empty report.
                        if (! response.data.data.results) {
                            return;
                        }

                        this.results = response.data.data.results;
                        this.filteredResults = { ...this.results };
                        this.isLoading = false;
                    })
                    .catch(err => {
                        window.console.error(err);
                    });
            },

            /**
             * Filter this.results by layer and return an object containing three keys:
             *
             * @type {object} metrics - The filtered list of metrics
             * @type {object} opportunities - The filtered list of opportunities
             * @type {int} length - The size of metrics + opportunities.
             */
            filterByLayer(layer) {
                const metrics = Object.fromEntries(Object.entries(this.results.metrics).filter(audit => {
                    return layer === audit[1].layer;
                }));
                const opportunities = Object.fromEntries(Object.entries(this.results.opportunities).filter(audit => {
                    return layer === audit[1].layer;
                }));

                return {
                    metrics,
                    opportunities,
                    length: Object.keys(metrics).length + Object.keys(opportunities).length,
                }
            },

            /**
             * Adds .filter-active to buttons that represent the current filter.
             *
             * @param {string} filter - The filter name.
             *
             * @return {string}
             */
            filterState(filter) {
                return this.isFilterActive(filter) ? 'filter-active' : '';
            },

            /**
             * Is the current filter type active?
             *
             * @param {string} filter - The filter name.
             *
             * @return {bool}
             */
            isFilterActive(filter) {
                return this.currentFilter === filter;
            },

            /**
             * Display a scan failure.
             */
            markScanFailed() {
                this.isLoading = false;
                this.scanFailed = true;
            },

            /**
             * Render a Markdown string.
             *
             * @param {string} str - The Markdown string to parse.
             *
             * @return string
             */
            markdown(str) {
                return md.render(str);
            },
        },

        beforeMount() {
            // If results were explicitly passed in, forego any loading behavior.
            if (this.results) {
                this.isLoading = false;
            }
        },

        mounted() {
            // Listen for events broadcast via Pusher.
            Echo.channel(`scans.${this.id}`)
                .listen('.scan.completed', this.fetchResults)
                .listen('.scan.failed', this.markScanFailed);

            // Attempt to grab the report in case it's already complete.
            if (! this.results) {
                this.fetchResults();
            }
        },
    };
</script>
