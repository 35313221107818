<template>
    <div class="letter-grade">
        <span :class="gradeClass"
            class="letter-grade-letter"
        >
            {{ letterGrade }}
        </span>

        <span class="percentage">
            {{ percent }}%
        </span>
    </div>
</template>

<script>
    import { getLetterGrade } from '../../letter-grade';

    export default {
        props: {
            score: {
                required: true,
                type: Number,
            },
        },

        data() {
            const letter = getLetterGrade(this.score);

            return {
                gradeClass: 'grade-' + letter.replace('-', '-minus').replace('+', '-plus').toLowerCase(),
                letterGrade: letter,
                percent: Math.round(this.score * 100),
            };
        },
    };
</script>
