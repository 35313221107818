/**
 * Methods for converting a score to a letter grade.
 */

/**
 * Convert a number between 0 and 1 into a letter grade.
 *
 * @param {Number} score - The score, a value between 0 and 1.
 *
 * @return {string}
 */
const getLetterGrade = function (score) {
    if (0.98 <= score) {
        return 'A+';
    }
    if (0.92 <= score) {
        return 'A';
    }
    if (0.90 <= score) {
        return 'A-';
    }
    if (0.88 <= score) {
        return 'B+';
    }
    if (0.82 <= score) {
        return 'B';
    }
    if (0.80 <= score) {
        return 'B-';
    }
    if (0.78 <= score) {
        return 'C+';
    }
    if (0.72 <= score) {
        return 'C';
    }
    if (0.70 <= score) {
        return 'C-';
    }
    if (0.68 <= score) {
        return 'D+';
    }
    if (0.62 <= score) {
        return 'D';
    }
    if (0.60 <= score) {
        return 'D-';
    }

    return 'F';
}

module.exports = {
    getLetterGrade,
};
