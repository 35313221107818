<!--
    Dynamically convert bytes to more human-readable values.

    EXAMPLES

    # 150 bytes
    <bytes bytes="150" />

    # 1.5 KB
    <bytes bytes="1500" />

    # 1.4 MB
    <bytes bytes="1500000" />
-->

<template>
    <span v-if="wasConverted"
        :title="title"
    >
        {{ value }} {{ unit }}
    </span>
    <span v-else>
        {{ value }} bytes
    </span>
</template>

<script>
    export default {
        props: {
            bytes: {
                required: true,
                type: Number,
            },
        },

        data() {
            let unit = 'B',
                value = this.bytes;

            if (this.bytes >= 1073741824) {
                unit  = 'GB';
                value = (this.bytes / 1073741824).toFixed(1);
            } else if (this.bytes > 1048576) {
                unit  = 'MB';
                value = (this.bytes / 1048576).toFixed(1);
            } else if (this.bytes > 1024) {
                unit  = 'KB';
                value = (this.bytes / 1024).toFixed(1);
            }

            return {
                title: Math.round(this.bytes) + ' bytes',
                unit: unit,
                value: value,
                wasConverted: 'B' !== unit,
            };
        },
    };
</script>
