<!--
    Render a simplified table of key:value pairings.
-->

<template>
    <div class="table-wrap">
        <table class="data-table">
            <tbody>
                <tr v-for="item, key in items"
                    :key="item.key"
                >
                    <th scope="row">
                        {{ key }}
                    </th>
                    <td>
                        {{ item }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: {
            items: {
                required: true,
                type: Object,
            },
        },
    };
</script>
